import React from "react";
import Slider from "react-slick";
import Overlay from "../Overlay";

const initWidth = 768;

class ReactSlickSlider extends React.Component {
    static instance() {
        if (this._instance) return this._instance;
        return this._instance = new this();
    }

    getMeta(url, callback) {
        const img = new Image();
        img.src = url;
        img.onload = function () {
            callback(this.width, this.height);
        }
    }

    constructor(props) {
        super(props);
        // Set initial values with fallback if props are undefined
        const initialIndex = (props && props.initialIndex !== undefined) ? Number(props.initialIndex) : 0;
        const initialHeight = (props && props.initHeight !== undefined) ? Number(props.initHeight) : 576;

        this.state = {
            size: 0,
            leftOfset: 0,
            topOffset: 0,
            nav1: null,
            nav2: null,
            currentSlideIndex: initialIndex,
            width: initWidth,
            height: 0,
            initHeight: initialHeight,
            resSliderTwoVar: 1.25,
            extraHeight: 25,
            slidesToShow: 5
        };

        // Create refs
        this.parentRef = React.createRef();
        this.sliderRef1 = React.createRef();
        this.sliderRef2 = React.createRef();

        // Initialize overlay
        this.overlay = Overlay.instance();
        this.overlay.addOnClick(() => this.close());

        this.sliderPrev = document.getElementById('slider-arrow-prev');
        this.sliderNext = document.getElementById('slider-arrow-next');
        this.sliderButtonHome = document.getElementById('slider-arrows-group');
        this.closeElement = document.getElementById('slider-arrow-close');

        // Event listeners for slider controls
        window.addEventListener('keydown', this.handleKeydown);
        this.closeElement.addEventListener('click', this.close);
        this.sliderPrev.addEventListener('click', this.goPrev);
        this.sliderNext.addEventListener('click', this.goNext);

        this.current_vehicle_title = window.current_vehicle?.['@LISTING_TITLE'] ?? '';
    }

    handleKeydown = e => {
        if (e.keyCode === 37) this.goPrev();
        if (e.keyCode === 39) this.goNext();

        // On Esc press
        if (e.keyCode == 27) this.close();
    }
    goNext = () => {
        this.sliderRef1.current.slickNext(this.state.currentSlideIndex);
    }

    goPrev = () => {
        this.sliderRef1.current.slickPrev()
    }

    close = () => {
        this.overlay.hide();

        const slider = document.getElementById("react-slick-slider");
        slider.classList.add("hidden");

        this.sliderButtonHome.classList.add("hidden");
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.initialIndex !== this.props.initialIndex) {
            this.changeBothSliders( this.props.initialIndex);
        }
    }

    componentDidMount() {
        this.updateSize();
        // Set the refs after the component has mounted
        this.setState({
            nav1: this.sliderRef1.current,
            nav2: this.sliderRef2.current,
        });


        window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
        this.closeElement.removeEventListener('click', this.close);
        this.sliderPrev.removeEventListener('click', this.goPrev);
        this.sliderNext.removeEventListener('click', this.goNext);
    }

    onClickMenuSlider(index){
        this.changeBothSliders(index);
    }

    changeBothSliders(index){
        // this.sliderRef2.current.slickGoTo(index);
        this.sliderRef1.current.slickGoTo(index);
        this.setState({currentSlideIndex:index});
    }
    updateSize = () => {
        const window_width = window.innerWidth;
        const window_height = window.innerHeight;
        let newHeight = this.state.height;
        let newWidth = this.state.width;
        let slidesToShow = 5;
        let topOffset = 10;
        let extraHeight = 25
        let resSliderTwoVar = this.state.resSliderTwoVar;

        if (window_width < 769) {
            topOffset = window_height / 10;
            extraHeight = 40;
            if(window_width > 700){
                topOffset = topOffset * 0.5;
                extraHeight = extraHeight * 0.5;
            }
            newHeight = (window_width / (initWidth / this.state.initHeight)) * 0.80;
            newWidth = window_width * 0.80;
            slidesToShow = 3;
        } else if (window_width >= 769 && window_width < 900) {
            newHeight = this.state.initHeight * 0.70;
            newWidth = initWidth * 0.70;
        } else if (window_width >= 900 && window_width < 1200) {
            newHeight = this.state.initHeight * 0.80;
            newWidth = initWidth * 0.80;
        } else if (window_width >= 1200 && window_width <= 1440) {
            newHeight = this.state.initHeight * 0.9;
            newWidth = initWidth * 0.9;
        } else if (window_width > 1440 && window_width <= 1600) {
            newHeight = this.state.initHeight;
            newWidth = initWidth;
        } else if (window_width > 1920) {
            newHeight = this.state.initHeight * 0.88;
            newWidth = initWidth * 0.88;
            resSliderTwoVar = 0.5;
        }
        else{
            newHeight = this.state.initHeight;
            newWidth = initWidth;
        }

        const newRatio = newWidth / newHeight;
        extraHeight = extraHeight * newRatio;

        newHeight = Math.round(newHeight);
        newWidth = Math.round(newWidth);


        this.setState({
            height: newHeight,
            width: newWidth,
            slidesToShow: slidesToShow,
            extraHeight: extraHeight,
            resSliderTwoVar: resSliderTwoVar,
            size: this.parentRef.current.offsetHeight / 10 },

            () =>{
                    let offSet = ((window_width - this.parentRef.current.offsetWidth) / 2);
                    if(offSet < 0){
                        offSet = 20
                    }
                    this.setState({
                            leftOffset: offSet,
                            topOffset: topOffset
                        }
                    );
        });
    };
    handleAfterChange = (currentIndex) => {
        this.setState({currentSlideIndex:currentIndex});
    };

    render() {
        const { images } = this.props;
        const { nav2, leftOffset, topOffset, height, width, slidesToShow, extraHeight, resSliderTwoVar } = this.state;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            afterChange: this.handleAfterChange,
            initialSlide: this.state.currentSlideIndex,
    };

        return (
            <div className={`react-slick-slider`}

                 style={{
                     left: `${leftOffset}px`,
                     top: `${topOffset}px`,
                 }}
            >
                <style>
                    {`
  .slick-slider-one {
    .slick-list {
      height: ${height + extraHeight}px;
      width: ${4/3 * height}px;
    }
  }

  .image-wrapper {
    width: 100%;
    aspect-ratio: 4 / 3;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
    width: ${4/3 * height}px;
    height: ${height}px;
  }

  .image-wrapper img {
    width: 100%;
    object-fit: contain; 
    object-position: center; 
  }

  .slick-slider-two {
    height: ${((height + extraHeight)/5)}px;
  }
  `}
                </style>


                <div
                    style={{
                        height: `${height}px`,
                        width: `${4/3 * height}px`,
                        left: `${leftOffset}px`
                    }}
                    ref={this.parentRef}

                >
                    <div className="col-12">
                        <div className="slider-container">
                            <Slider
                                className="slick-slider-one"
                                asNavFor={nav2}
                                ref={this.sliderRef1}
                                {...settings}
                            >
                                {images.map((image, index) => (
                                    <div key={index} className="slider-container">
                                        <div className="row">
                                            <div className="col-9">
                                                <b>{this.current_vehicle_title}</b>
                                            </div>
                                            <div className="col-3 text-align-right">
                                                <b>{index + 1} / {images.length}</b>
                                            </div>
                                        </div>
                                        <div className="image-wrapper">
                                            <img src={image} alt={`Slide ${index + 1}`}/>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <Slider
                        className="slick-slider-two"
                        ref={this.sliderRef2}
                        slidesToShow={slidesToShow}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        centerMode={true}
                    >
                        {images.map((image, index) => (
                            <div key={index}>
                                <div className={`imgMargin`}>
                                    <img
                                        className={`${this.state.currentSlideIndex == index ? `slick-current-parent` : ``}`}
                                        onClick={() => {
                                            this.onClickMenuSlider(index)
                                        }}
                                        src={image} alt={`Slide ${index + 1}`}/>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default ReactSlickSlider;
