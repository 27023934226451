import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";
import ReactSlickSlider from "../components/react-slider/ReactSlickSlider";
import Overlay from "../components/Overlay";

export default class extends Controller {
    static targets = ["overlay"];

    async connect() {
        this.isReady = false;
        try {
            this.initHeight = await this.getImageInitHeight();
            this.currentImages = window.images_data
                .map(image =>
                    image["@url"] !== undefined
                        ? image["@url"]
                            .replace('aptest', 'autoplay') +
                        "?id=81040321&w=1152"
                        : undefined
                )
                .filter(url => url !== undefined);
            this.slickSlider = ReactSlickSlider.instance();
            this.isReady = true; // Set flag to true when initialization is complete
        } catch (error) {
            console.error("Initialization failed:", error);
        }
    }

    async getImageInitHeight() {
        // const firstValidImage = window.images_data.find(item => item['@url'] !== undefined);
        //
        // if (firstValidImage && firstValidImage['@url']) {
        //     try {
        //         const height = await this.getMeta(
        //             firstValidImage["@url"].replace('aptest', 'autoplay') +
        //             "?id=81040321&w=768&q=80&compress=true"
        //         );
        //         return height;
        //     } catch (error) {
        //         return 576; // Default height in case of error
        //     }
        // }

        return Math.max(window.innerHeight * 0.75, 500);
        // return 500; // Default height if no valid image is found
    }

    getMeta(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;

            img.onload = () => {
                resolve(img.height); // Resolve the promise with the image height
            };

            img.onerror = (error) => {
                reject(`Failed to load image: ${error}`); // Handle errors if the image fails to load
            };
        });
    }

    showSlider(event) {
        if (!this.isReady) {
            console.warn("Slider not ready. Initialization in progress.");
            return;
        }

        const slider = document.getElementById("react-slick-slider");

        //Figure out how to use this only when the screen is re-sized while slider is closed
        // ReactDOM.unmountComponentAtNode(slider);

        slider.classList.remove("hidden");
        const clickedElement = event.target.closest("[data-index]");
        const index = clickedElement.dataset.index || 0;
        this.sliderButtonHome = document.getElementById('slider-arrows-group');
        this.overlay = Overlay.instance();
        this.overlay.show();
        this.sliderButtonHome.classList.remove("hidden");
        ReactDOM.render(
            <ReactSlickSlider
                images={this.currentImages}
                initialIndex={index}
                initHeight={this.initHeight}
            />,
            document.getElementById("react-slick-slider")
        );
    }

    hideSlider() {
        const overlay = document.getElementById("slider-overlay");
        overlay.classList.add("hidden");
        ReactDOM.unmountComponentAtNode(document.getElementById("react-slick-slider"));
    }
}
