import { axiosInstance } from '~/js/_services/config';

export const searchService = {
    saveSearch
}

/**
 * Makes an API request to save the given filters for an email.
 *
 * @param {object} filters - Search filters to save.
 * @param {string} email - Email to save the serch filters against.
 * @param {string} listingID - Listing ID of the most recent vehicle @LISTING_SEQ ; nullable
 * @return {Promise<*>}
 */
async function saveSearch(filters, email, listingID) {
    const { data } = await axiosInstance.post(
        '/api/v1/search/save',
        {
            listing: listingID,
            email: email,
            filters: filters,
        },
        {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        }
    );
    return data;
}
